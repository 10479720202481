import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';


const ServicesTable = ({ services }) => {

    function differenceInHours(dt2, dt1) 
    {
        var diff =(dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60 * 60;
        return Math.abs(Math.round(diff));    
    }

    const getServices = (day) => {
        let r = day?.filter(service => service.category === "R")
        let hm = day?.filter(service => service.category === "HM")
        let gt = day?.filter(service => service.category === "GT")
        let gl = day?.filter(service => service.category === "GL")
        let tg = day?.filter(service => service.category === "TG")
        let wd = day?.filter(service => service.category === "WD")
           
        let content = [];
        if(r?.length > 0) content.push(<span className='mx-1 rounded-2 p-1' style={{ color: "#EBEBEB", backgroundColor: "#7E828C"}}>{r?.reduce((minutes, service) => minutes = minutes + differenceInHours(new Date(service.time_from), new Date(service.time_to)), 0)}</span>)
        if(hm?.length > 0) content.push(<span className='mx-1 rounded-2 p-1' style={{ color: "#464F60", backgroundColor: "#EBEBEB"}}>{hm?.reduce((minutes, service) => minutes = minutes + differenceInHours(new Date(service.time_from), new Date(service.time_to)), 0)}</span>)
        if(gt?.length > 0) content.push(<span className='mx-1 rounded-2 p-1' style={{ color: "#464F60", backgroundColor: "#C0F2BC"}}>{gt?.reduce((minutes, service) => minutes = minutes + differenceInHours(new Date(service.time_from), new Date(service.time_to)), 0)}</span>)
        if(gl?.length > 0) content.push(<span className='mx-1 rounded-2 p-1' style={{ color: "#464F60", backgroundColor: "#C8DAF5"}}>{gl?.reduce((minutes, service) => minutes = minutes + differenceInHours(new Date(service.time_from), new Date(service.time_to)), 0)}</span>)
        if(tg?.length > 0) content.push(<span className='mx-1 rounded-2 p-1' style={{ color: "#464F60", backgroundColor: "#9F7748"}}>{tg?.reduce((minutes, service) => minutes = minutes + differenceInHours(new Date(service.time_from), new Date(service.time_to)), 0)}</span>)
        if(wd?.length > 0) content.push(<span className='mx-1 rounded-2 p-1' style={{ color: "#464F60", backgroundColor: "#F0F0F0"}}>{wd?.reduce((minutes, service) => minutes = minutes + differenceInHours(new Date(service.time_from), new Date(service.time_to)), 0)}</span>)
        return content;
    };

    const columns = [
        { field: 'name', headerName: 'Objekt', width: 200 },
        {
            field: 'monday', headerName: 'Montag ' + services[0]?.monday.date, width: 200, renderCell: (params) => (
                <>
                    {getServices(params.row.monday.services)}
                </>
            )

        },
        {
            field: 'tuesday', headerName: 'Dienstag ' + services[0]?.tuesday.date, width: 200,renderCell: (params) => (
                <>
                    {getServices(params.row.tuesday.services)}
                </>
            )
        },
        {
            field: 'wednesday', headerName: 'Mittwoch ' + services[0]?.wednesday.date, width: 200, renderCell: (params) => (
                <>
                    {getServices(params.row.wednesday.services)}
                </>
            )
        },
        {
            field: 'thursday', headerName: 'Donnerstag ' + services[0]?.thursday.date, width: 200, renderCell: (params) => (
                <>
                    {getServices(params.row.thursday.services)}
                </>
            )
        },
        {
            field: 'friday', headerName: 'Freitag ' + services[0]?.friday.date, width: 200, renderCell: (params) => (
                <>
                    {getServices(params.row.friday.services)}
                </>
            )
        },
        {
            field: 'saturday', headerName: 'Samstag ' + services[0]?.saturday.date, width: 200, renderCell: (params) => (
                <>
                    {getServices(params.row.saturday.services)}
                </>
            )
        },
        {
            field: 'sunday', headerName: 'Sonntag ' + services[0]?.sunday.date, width: 200, renderCell: (params) => (
                <>
                    {getServices(params.row.sunday.services)}
                </>
            )
        }
    ];

    return (
        <div style={{ width: '100%', height: "70vh", backgroundColor: '#FFF' }}>
            <DataGrid
                rows={services}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
            />
        </div>

    );
}

export default ServicesTable;