import * as React from 'react';
import { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { axiosPrivate } from "../api/axios";
import ServicesTable from '../components/Tables/ServicesTable';
import { getCurrentWeek } from '../util/DateHelper';

function Services() {
    const [isLoading, setIsLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [calendarWeek, setCalendarWeek] = useState(getCurrentWeek())
    const [servicesStats, setServicesStats] = useState({
        R: 0,
        HM: 0,
        GT: 0,
        GL: 0,
        TG: 0,
        WD: 0
    })

    useEffect(() => {
        const fetchData = async () => {
            const response = await axiosPrivate.get(`/tt/objects?week=${calendarWeek}&year=2024`)
            const data = response.data.data
            const loadedServices = []            
            for (const key in data) {
                loadedServices.push({
                    id: data[key].id,
                    name: data[key].name,
                    ks: data[key].ks,
                    pincode: data[key].pincode,
                    city: data[key].city,
                    monday: data[key].monday,
                    tuesday: data[key].tuesday,
                    wednesday: data[key].wednesday,
                    thursday: data[key].thursday,
                    friday: data[key].friday,
                    saturday: data[key].saturday,
                    sunday: data[key].sunday,                    
                })
            }

            setServices(loadedServices)
            setIsLoading(false)
        }

        const fetchStats = async () => {
            const response = await axiosPrivate.get(`/tt/objects`)
            const data = response.data.data
            
            let r = 0
            let hm = 0
            let gt = 0
            let gl = 0
            let tg = 0
            let wd = 0

            for (const key in data) {                
                    r += data[key].services?.filter(service => service.category === "R").length
                    hm += data[key].services?.filter(service => service.category === "HM").length
                    gt += data[key].services?.filter(service => service.category === "GT").length
                    gl += data[key].services?.filter(service => service.category === "GL").length
                    tg += data[key].services?.filter(service => service.category === "TG").length
                    wd += data[key].services?.filter(service => service.category === "WD").length                
            }

            setServicesStats({
                R: r,
                HM: hm,
                GT: gt,
                GL: gl,
                TG: tg,
                WD: wd
            })
           
            setIsLoading(false)
        }

        fetchData()
        fetchStats()
    }, [calendarWeek])

    const getCalendarWeeks = () => {
        let content = [];
        for (let i = 1; i <= 52; i++) {
            content.push(<Dropdown.Item key={i} onClick={() => setCalendarWeek(i)}>KW {i}</Dropdown.Item>);
        }
        return content;
    };

    return (
        <div style={{ width: "100%" }}>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                <React.Fragment>


                    <div className="row mx-2 my-3 p-3 shadow rounded-5 d-flex justify-content-xl-between justify-content-start">                                                    
                                <div className='col-12 col-xl-3 my-1 d-flex'>
                                    <Dropdown id="dropDownWebsite">
                                        <Dropdown.Toggle variant="primary" id="dropdown-size-small" style={{ backgroundColor: '#82B0B8', border: "none" }}>
                                            KW {calendarWeek}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ overflowY: 'scroll', maxHeight: "10rem" }}>
                                            {getCalendarWeeks()
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <input
                                        className="rounded-5 p-1 ps-2 mx-1"
                                        type="text"
                                        placeholder="Objekt..."
                                    />
                                    <input
                                        className="rounded-5 p-1 ps-2 me-1"
                                        type="text"
                                        placeholder="Ort..."
                                        style={{width:"5rem"}}
                                    />
                                    
                                    <input
                                        className="rounded-5 p-1 ps-2 mx-1"
                                        type="text"
                                        placeholder="Plz..."
                                        style={{width:"5rem"}}
                                    />
                                </div>
                                <div className='col-12  col-xl-3 my-1 '>
                                    <span className='fw-bold mx-1' style={{ color: "#000" }}>R</span><span className='mx-1 rounded-2 p-1' style={{ color: "#000", backgroundColor: "#F0F0F0" }}>{servicesStats.R}</span>
                                    <span className='fw-bold mx-1' style={{ color: "#7E7E7E" }}>HM</span><span className='mx-1 rounded-2 p-1' style={{ color: "#7E7E7E", backgroundColor: "#F0F0F0" }}>{servicesStats.HM}</span>
                                    <span className='fw-bold mx-1' style={{ color: "#348B2D" }}>GT</span><span className='mx-1 rounded-2 p-1' style={{ color: "#348B2D", backgroundColor: "#F0F0F0" }}>{servicesStats.GT}</span>
                                    <span className='fw-bold mx-1' style={{ color: "#2256A4" }}>GL</span><span className='mx-1 rounded-2 p-1' style={{ color: "#2256A4", backgroundColor: "#F0F0F0" }}>{servicesStats.GL}</span>
                                    <span className='fw-bold mx-1' style={{ color: "#9F7748" }}>TG</span><span className='mx-1 rounded-2 p-1' style={{ color: "#9F7748", backgroundColor: "#F0F0F0" }}>{servicesStats.TG}</span>
                                    <span className='fw-bold mx-1' style={{ color: "#67BBBB" }}>WD</span><span className='mx-1 rounded-2 p-1' style={{ color: "#67BBBB", backgroundColor: "#F0F0F0" }}>{servicesStats.WD}</span>
                                </div>
                                <div className='col-12 col-xl-3 my-1'>
                                    <input
                                        className="rounded-5 p-1 ps-2 mx-1"
                                        type="text"
                                        placeholder="Suche..."
                                    />
                                </div>                                              
                    </div>
                    <ServicesTable services={services} />
                </React.Fragment>
            }
        </div>
    );
}

export default Services;