import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";


function Topbar() {    
    const [user, setUser] = useState()
    const [isTimeTrackActive, setIsTimeTrackActive] = useState()

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate()
    
    useEffect(() => {
        const fetchUserData = async () => {                        
            const response = await axiosPrivate.get("/core/users");
            setUser(response.data)
        }

        fetchUserData().catch(error => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        const fetchTimeTrackState = async () => {      
            if(user) {                  
                const response = await axiosPrivate.get("/tt/users/" + user.Name + "/status");
                setIsTimeTrackActive(response.data)            
            }
        }

        fetchTimeTrackState().catch(error => {
            console.log(error)
        })
    }, [user])

    const toggleTimeTrack = async () => {
        await axiosPrivate.put("/tt/users/" + user.Name + "/status", {
            username: user.name
        }) 
        
        const response = await axiosPrivate.get("/tt/users/" + user.Name + "/status");
        setIsTimeTrackActive(response.data)  
    }

    const logout = async () => {
        secureLocalStorage.removeItem("user")
        secureLocalStorage.removeItem("password")        
        navigate('/login');
    }    

    return (
        <div className='row'>
            <div className='col d-flex mx-3 mt-3 mb-2 justify-content-between'>
                <div className='d-flex'>
                    {!isTimeTrackActive?
                        <div className="button d-flex justify-content-evenly" onClick={toggleTimeTrack}>                        
                            <span className='m-1'><i className="bi bi-arrow-right-circle-fill" style={{color: "#8FB885", fontSize:'2rem'}}></i></span>
                            <span className='m-3' >Anmelden</span>                        
                        </div>
                        :
                        <div className="button d-flex justify-content-evenly" style={{backgroundColor:'#4A4A4A'}}  onClick={toggleTimeTrack}>                        
                            <span className='m-3' style={{color:"white"}}>Abmelden</span>
                            <span className='m-1'><i className="bi bi-arrow-left-circle-fill" style={{color: "#C06363", fontSize:'2rem'}}></i></span>                        
                        </div>
                    }
                    <div className="button px-3 py-2 ms-3" style={{borderRadius: "50%"}}>                    
                        <i className="bi bi-bell" style={{fontSize:'21pt'}}></i>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className="button d-flex justify-content-evenly" style={{cursor: "default"}}>
                        <span className='round-button py-1 px-3 ms-1 my-2'>{user ? user.Name.toUpperCase().charAt(0) : "D"}</span>
                        <span className='m-3 d-none d-md-block'>{user ? user.Name : ""}</span>
                        <span className='m-1' style={{cursor: "pointer"}} onClick={logout}><i className="bi bi-box-arrow-left me-3"style={{fontSize:'2rem'}}></i></span>
                        
                    </div>
                    <div className='dropdown'>
                        <button className="button px-3 py-2 ms-3" style={{borderRadius: "50%"}} data-bs-toggle="dropdown" aria-expanded="false">                    
                            <i className="bi bi-gear" style={{fontSize:'21pt'}}></i>
                        </button>
                        <ul className="dropdown-menu">
                            <li>Test</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar