import * as React from 'react';
import { useEffect, useState } from "react";
import ObjectsTable from '../components/Tables/ObjectsTable';

import useAxiosPrivate from "../hooks/useAxiosPrivate";


function Objects() {    
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState([])      
    const axiosPrivate = useAxiosPrivate();  

    useEffect(() => {
        const fetchObjects = async () => {
            const response = await axiosPrivate.get("/tt/objects")
            var data = await response.data.data;            
            const loadedObjects = [];      
            
            for (const key in data) {
                loadedObjects.push({
                    id: data[key].id,
                    name: data[key].name,
                    ks: data[key].ks,
                    pincode: data[key].pincode,
                    city: data[key].city
                })                
            }      
                        
            setObjects(loadedObjects)
            setIsLoading(false)
        }

        fetchObjects()
    }, [])

    

    return (
        <div>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
            <ObjectsTable objects={objects}/>
            }
        </div>
    );
}

export default Objects;