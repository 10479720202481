import { Navigate, Outlet, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ admin }) => {    
    const location = useLocation();   
    const { auth } = useAuth()
    return (
        secureLocalStorage.getItem("user")?
            <Outlet />
            : 
            <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;