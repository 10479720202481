import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import classes from "./MainNavigation.module.css";

function MainNavigation() {
    const {auth} = useAuth()
    return <header className={classes.header}>
        <nav>
            <ul className="nav flex-column mt-4 justify-content-between ">
                <li className="d-none d-sm-block">
                    <div className="input-group rounded p-2">
                        <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                        
                    </div>
                </li>
                <li className="nav-item py-2">
                    <NavLink to="/" className="mx-3 nav-link d-flex justify-content-start" end>
                        <span className='round-button py-2 px-3 ms-1 my-1'><i className="bi-columns-gap"></i></span>
                        <span className='m-3 d-none d-sm-block'>Dashboard</span>
                    </NavLink>
                </li>
                <li className="nav-item py-2">
                    <NavLink to="/objects" className="mx-3 nav-link d-flex justify-content-start">
                        <span className='round-button py-2 px-3 ms-1 my-1'><i className="bi-house "></i></span>
                        <span className='m-3 d-none d-sm-block'>Objekte</span>
                    </NavLink>
                </li>                
                <li className="nav-item py-2">
                    <NavLink to="/employees" className="mx-3 nav-link d-flex justify-content-start">
                        <span className='round-button py-2 px-3 ms-1 my-1'><i className="bi-person "></i></span>
                        <span className='m-3 d-none d-sm-block'>Mitarbeiter</span>
                    </NavLink>
                </li>            
                <li className="nav-item py-2">
                    <NavLink to="/services" className="mx-3 nav-link d-flex justify-content-start">
                        <span className='round-button py-2 px-3 ms-1 my-1'><i className="bi-tools "></i></span>
                        <span className='m-3 d-none d-sm-block'>Services</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    </header>
}

export default MainNavigation;