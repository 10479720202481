import { encode as base64_encode } from 'base-64';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
const LOGIN_URL = '/core/users';

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const {setAuth} = useAuth()
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');   
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.get(LOGIN_URL,
                {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Basic ${base64_encode(user + ':' + pwd)}`}
                }
            );
                        
            if(response !== null) {                    
                secureLocalStorage.setItem("user", user)       
                secureLocalStorage.setItem("password", pwd)    
                const admin = response.data.Admin
                setAuth({admin})   
            }
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            console.log(err)
            // if (!err?.response) {
            //     setErrMsg('No Server Response');
            // } else if (err.response?.status === 400) {
            //     setErrMsg('Missing Username or Password');
            // } else if (err.response?.status === 401) {
            //     setErrMsg('Unauthorized');
            // } else {
            //     setErrMsg('Login Failed');
            // }
        }
    }

    return (
        <>
            <div className='header'>Sales First</div>
                <div className='container mt-5'>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label for="exampleInputEmail1">Email address</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => setUser(e.target.value)}/>                    
                        </div>
                        <div className="form-group mt-3">
                            <label for="exampleInputPassword1">Password</label>
                            <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={(e) => setPwd(e.target.value)}/>
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">Submit</button>
                    </form>
                </div>
            <div className='footer'>Software made by GetCoding</div>
        </>

    )
}

export default Login
