import { Outlet } from "react-router-dom";
import MainNavigation from "./Navbar/MainNavigation";
import Topbar from "./Navbar/Topbar";

const Layout = () => {
    return (
        <>
            <div className="main">
                <Topbar />
                <div className="d-flex justify-content-start mt-3">                                        
                    <MainNavigation />                
                    <Outlet />              
                </div>
            </div>
        </>
    )
}

export default Layout
