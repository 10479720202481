import * as React from 'react';
import { useState, useEffect } from "react";


function Dashboard() {    
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(false)
        }

        fetchData()
    }, [])

    return (
        <div>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                <div>Dashboard</div>
            }            
        </div>
    );
}

export default Dashboard;