import * as React from "react";
import { useEffect, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { formatDate } from "../util/DateHelper.js";

import "react-calendar/dist/Calendar.css";

function EmployeeDetail() {
    const [isLoading, setIsLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const [employee, setEmployee] = useState()
    let { id } = useParams();

  useEffect(() => {
    const fetchEmployee = async () => {
      const response = await axiosPrivate.get(`/tt/employees/${id}`);
      setEmployee(response.data);
      setIsLoading(false);
    };

    fetchEmployee();
  }, [id, axiosPrivate]);

    return (
        <div className='container'>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                <>

                    <div className='row justify-content-between'>
                        <div className='col-8'>
                            <div className='row border shadow p-2'>
                                <h4>Mitarbeiter</h4>
                                <div className='row'>
                                    <div className='col'>
                                        <div>Name</div>
                                        <div className='border rounded-5 p-1'>{employee.name}</div>
                                    </div>
                                    <div className='col'>
                                        <div>Telefon</div>
                                        <div className='border rounded-5 p-1'>{employee.phone_number}</div>
                                    </div>
                                    <div className='col'>
                                        <div>Geburtstag</div>
                                        <div className='border rounded-5 p-1'>{formatDate(employee.birthdate)}</div>
                                    </div>
                                    <div className='col'>
                                        <div>Arbeitsstunden</div>
                                        <div className='border rounded-5 p-1'>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" style={{width: "50%", backgroundColor:"#ADEDA8"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">20/40</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <div>Nr</div>
                                        <div className='border rounded-5 p-1'>{employee.nr}</div>
                                    </div>
                                    <div className='col'>
                                        <div>Beginn</div>
                                        <div className='border rounded-5 p-1'>{formatDate(employee.contract.begin)}</div>
                                    </div>
                                    <div className='col'>
                                        <div>Beschäftigungsart</div>
                                        <div className='border rounded-5 p-1'>{employee.contract.type}</div>
                                    </div>
                                    <div className='col'>
                                        <div>Abteilung</div>
                                        <div className='border rounded-5 p-1'>{employee.contract.department}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5 justify-content-between'>
                                <div className='col-5 border shadow'>
                                    <h4>Objekte</h4>
                                </div>
                                <div className='col-5 border shadow'><Calendar /></div>
                            </div>
                            <div className='row mt-5 border shadow'>
                                <div className='col'>
                                    <h4>Historie</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-3 me-5'>
                            <div className='row border shadow'>
                                <div className='col'>
                                    <h4>Aktive Tags</h4>
                                </div>
                            </div>
                            <div className='row mt-5 border shadow'>
                                <div className='col'>
                                    <h4>Wöchentlicher Plan</h4>
                                </div>
                            </div>
                            <div className='row mt-5 border shadow'>
                                <div className='col'>
                                    <h4>Anträge</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default EmployeeDetail;
