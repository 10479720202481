import * as React from "react";
import { useEffect, useState } from "react";

import { axiosPrivate } from "../api/axios";
import EmployeeTable from '../components/Tables/EmployeeTable';

function Employees() {
    const [isLoading, setIsLoading] = useState(true);
    const [employees, setEmployees] = useState([])  
    const [employeeStatistics, setEmployeeStatistics] = useState()

    useEffect(() => {
        const fetchObjects = async () => {
            const response = await axiosPrivate.get("/tt/employees");
            var data = await response.data.data;
            const loadedEmployees = [];

            for (const key in data) {
                loadedEmployees.push({
                    id: data[key].id,
                    name: data[key].name,
                    nr: data[key].nr,
                    birthdate: data[key].birthdate,
                    begin: data[key].contract.begin,
                    type: data[key].contract.type,
                    hours: 0,
                    department: data[key].contract.department,
                });
            }

            setEmployees(loadedEmployees);
            setIsLoading(false);
        };

        fetchObjects();
    }, []);

    useEffect(() => {
        const fetchEmployeeStatistics = async () => {
            const response = await axiosPrivate.get("/tt/employees/stats");
            var data = await response.data;
            setEmployeeStatistics(data);
        };

        fetchEmployeeStatistics();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                <>
                    <div className="row d-flex justify-content-evenly" >
                        <div className="text-start col-xl-3 col-md-12 mx-3 p-3 shadow rounded-5">
                            <div className="d-flex justify-content-start ps-3 fw-bold">Anzahl Mitarbeiter</div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">HM</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="text-align-start fw-bold me-2">TG</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">GT</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="fw-bold me-2">WD</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">GL</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="fw-bold me-2">Total</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                        </div>
                        <div className="text-start col-xl-3 col-md-12 mx-3 p-3 shadow rounded-5">
                            <div className="d-flex justify-content-start ps-3 fw-bold">Arbeitsstunden</div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">HM</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="text-align-start fw-bold me-2">TG</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">GT</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="fw-bold me-2">WD</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">GL</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="fw-bold me-2">Total</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                        </div>
                        <div className="text-start col-xl-3 col-md-12 mx-3 p-3 shadow rounded-5">
                            <div className="d-flex justify-content-start ps-3 fw-bold">Beschäftigungsarten</div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">HM</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="text-align-start fw-bold me-2">TG</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">GT</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="fw-bold me-2">WD</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                            <div className="row d-flex justify-content-between px-3">
                                <div className="col-6"><span className="fw-bold me-2">GL</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                                <div className="col-6"><span className="fw-bold me-2">Total</span> {employeeStatistics?employeeStatistics.employee_count_stats[2].count:""}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-2 my-3 p-3 shadow rounded-5">
                        <div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <input
                                        className="rounded-5 p-1 ps-2 me-1"
                                        type="text"
                                        placeholder="Ort..."
                                    />
                                    <input
                                        className="rounded-5 p-1 ps-2 mx-1"
                                        type="text"
                                        placeholder="Objekt..."
                                    />
                                    <input
                                        className="rounded-5 p-1 ps-2 mx-1"
                                        type="text"
                                        placeholder="Plz..."
                                    />
                                </div>
                                <div>
                                    <input
                                        className="rounded-5 p-1 ps-2 mx-1"
                                        type="text"
                                        placeholder="Suche..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <EmployeeTable employees={employees} />
                </>
            }
        </div>
    );
}

export default Employees;
