import { Route, Routes } from 'react-router-dom';
import Employees from './views/Employees'
import Layout from './components/Layout';
import Login from './components/Login';
import Missing from './components/Missing';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';
import EmployeeDetail from './views/EmployeeDetail';
import Objects from './views/Objects';
import Services from './views/Services';
import Dashboard from './views/Dashboard';

function App() {

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="unauthorized" element={<Unauthorized />} />

        <Route element={<RequireAuth/>}>
          <Route path="" element={<Dashboard admin={"false"}/>} />
          <Route path="objects" element={<Objects />} />
          <Route path="employees" element={<Employees />} />
          <Route path="services" element={<Services />} />
          <Route path="employees/:id" element={<EmployeeDetail />} />
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;