import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../util/DateHelper';


const EmployeeTable = ({ employees }) => {
    const navigate = useNavigate();
    const columns = [               
        { field: 'nr', headerName: 'Nr', width: 200 },
        { field: 'name', headerName: 'Mitarbeiter', width: 200 },
        { field: 'begin', headerName: 'Beginn', width: 130, valueGetter: (params) => { return formatDate(params.value)} },
        { field: 'birthdate', headerName: 'Geburtstag', width: 200, valueGetter: (params) => { return formatDate(params.value)} },
        { field: 'type', headerName: 'Beschäftigungsart', width: 160 },
        { field: 'hours', headerName: 'Vertragsstunden', width: 150, renderCell: (params) => (
            <div class="progress" style={{width: "150"}}>
                <div class="progress-bar bg-danger" role="progressbar" style={{width: "100px"}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="50">44/40</div>
            </div>
        )
        },
        { field: 'department', headerName: 'Abteilung', width: 130 }
    ];

    const handleRowClick = (params) => {
        navigate("/employees/" + params.row.id)
    }

    return (        
        <div style={{ width: '100%', height: "70vh", backgroundColor: '#FFF' }}>            
            <DataGrid
                rows={employees}
                columns={columns}
                initialState={{
                    pagination: {
                      paginationModel: { pageSize: 25, page: 0 },
                    },
                  }}
                onRowClick={handleRowClick}
                pageSizeOptions={[5, 10, 25, 50, 100]}
            />            
        </div>

    );
}

export default EmployeeTable;