import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';


const ObjectsTable = ({ objects }) => {
  
    const columns = [               
        { field: 'ks', headerName: 'K/S', width: 200 },
        { field: 'name', headerName: 'Object', width: 200 },
        { field: 'pincode', headerName: 'PLZ', width: 130 },
        { field: 'city', headerName: 'Ort', width: 200 },
        { field: 'HM', headerName: 'HM', width: 160 },
        { field: 'GT', headerName: 'GT', width: 130 },
        { field: 'GL', headerName: 'GL', width: 130 },
        { field: 'SR', headerName: 'SR', width: 130 },
        { field: 'TG', headerName: 'TG', width: 130 },
        { field: 'WD', headerName: 'WD', width: 130 }
    ];

    return (        
        <div style={{ width: '100%', height: "70vh", backgroundColor: '#FFF' }}>            
            <DataGrid
                rows={objects}
                columns={columns}
                initialState={{
                    pagination: {
                      paginationModel: { pageSize: 25, page: 0 },
                    },
                  }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
            />            
        </div>

    );
}

export default ObjectsTable;